<template>
	<div>
		<!-- {{chartOptions}} -->
	<!-- <b-col cols="12" class="shadow mt-5"> -->
		<b-row>
			<b-col>
				<h3>
					{{ chartOptions.chart.id }}
				</h3>
			</b-col>
			<b-col>
				<b-form-group>
					<b-form-radio-group
						id="id"
						buttons
						v-model="type"
						:options="['bar', 'radar']"
						size="sm"
						name="chart-type-select"
						@click="changeType()"
					></b-form-radio-group>
				</b-form-group>
			</b-col>
		</b-row>

		<apexcharts :options="chartOptions" :series="series" :type="type">
		</apexcharts>
	</div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
	name: "Chart",
	components: {
		apexcharts: VueApexCharts,
	},
	props: {
		chartOptions: {
			type: Object,
			default: () => {},
		},
		series: {
			type: Array,
			default: () => [],
		},
		type: {
			type: String,
			default: () => "",
		},
	},

	computed: {
		changeType() {
			return this.type;
		},
	},
};
</script>

<style>
input[type="radio"] {
	display: none;
}
</style>