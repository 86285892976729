<template>
  <b-row>
    <b-col
      class="my-1 d-flex flex-row justify-content-start align-items-center"
    >
      <label for="range-1" class="mx-1">{{ filterInput.name }}: </label>
      <b-form-input
        id="minRelevance"
        v-model="filterInput.value"
        type="range"
        :min="filterInput.min"
        :max="filterInput.max"
        :step="filterInput.step"
        number
      ></b-form-input>

      {{ Number(filterInput.value) }}
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "FilterInput",
  props: {
    filterInput: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    // updateData() {
    //   this.$parent.updateData();
    // },
  },
};
</script>
